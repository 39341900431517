import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as courtsWjecITiRV5Meta } from "/usr/src/app/pages/courts.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as _91id_93a9ICq99052Meta } from "/usr/src/app/pages/matches/[id].vue?macro=true";
import { default as indexgkcTDAZP3yMeta } from "/usr/src/app/pages/matches/index.vue?macro=true";
import { default as setupvsWCBUEfYtMeta } from "/usr/src/app/pages/matches/setup.vue?macro=true";
import { default as indexw5NEbH4WE5Meta } from "/usr/src/app/pages/players/index.vue?macro=true";
import { default as _91id_93FZsvi8vktLMeta } from "/usr/src/app/pages/profile/[id].vue?macro=true";
import { default as settingsvvrkG75HvUMeta } from "/usr/src/app/pages/profile/settings.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pvGVZEAWcBMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pvGVZEAWcBMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pvGVZEAWcBMeta || {},
    alias: _91_46_46_46slug_93pvGVZEAWcBMeta?.alias || [],
    redirect: _91_46_46_46slug_93pvGVZEAWcBMeta?.redirect,
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: courtsWjecITiRV5Meta?.name ?? "courts",
    path: courtsWjecITiRV5Meta?.path ?? "/courts",
    meta: courtsWjecITiRV5Meta || {},
    alias: courtsWjecITiRV5Meta?.alias || [],
    redirect: courtsWjecITiRV5Meta?.redirect,
    component: () => import("/usr/src/app/pages/courts.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRGcvZC480vMeta?.name ?? "login",
    path: loginRGcvZC480vMeta?.path ?? "/login",
    meta: loginRGcvZC480vMeta || {},
    alias: loginRGcvZC480vMeta?.alias || [],
    redirect: loginRGcvZC480vMeta?.redirect,
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93a9ICq99052Meta?.name ?? "matches-id",
    path: _91id_93a9ICq99052Meta?.path ?? "/matches/:id()",
    meta: _91id_93a9ICq99052Meta || {},
    alias: _91id_93a9ICq99052Meta?.alias || [],
    redirect: _91id_93a9ICq99052Meta?.redirect,
    component: () => import("/usr/src/app/pages/matches/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgkcTDAZP3yMeta?.name ?? "matches",
    path: indexgkcTDAZP3yMeta?.path ?? "/matches",
    meta: indexgkcTDAZP3yMeta || {},
    alias: indexgkcTDAZP3yMeta?.alias || [],
    redirect: indexgkcTDAZP3yMeta?.redirect,
    component: () => import("/usr/src/app/pages/matches/index.vue").then(m => m.default || m)
  },
  {
    name: setupvsWCBUEfYtMeta?.name ?? "matches-setup",
    path: setupvsWCBUEfYtMeta?.path ?? "/matches/setup",
    meta: setupvsWCBUEfYtMeta || {},
    alias: setupvsWCBUEfYtMeta?.alias || [],
    redirect: setupvsWCBUEfYtMeta?.redirect,
    component: () => import("/usr/src/app/pages/matches/setup.vue").then(m => m.default || m)
  },
  {
    name: indexw5NEbH4WE5Meta?.name ?? "players",
    path: indexw5NEbH4WE5Meta?.path ?? "/players",
    meta: indexw5NEbH4WE5Meta || {},
    alias: indexw5NEbH4WE5Meta?.alias || [],
    redirect: indexw5NEbH4WE5Meta?.redirect,
    component: () => import("/usr/src/app/pages/players/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FZsvi8vktLMeta?.name ?? "profile-id",
    path: _91id_93FZsvi8vktLMeta?.path ?? "/profile/:id()",
    meta: _91id_93FZsvi8vktLMeta || {},
    alias: _91id_93FZsvi8vktLMeta?.alias || [],
    redirect: _91id_93FZsvi8vktLMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: settingsvvrkG75HvUMeta?.name ?? "profile-settings",
    path: settingsvvrkG75HvUMeta?.path ?? "/profile/settings",
    meta: settingsvvrkG75HvUMeta || {},
    alias: settingsvvrkG75HvUMeta?.alias || [],
    redirect: settingsvvrkG75HvUMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/settings.vue").then(m => m.default || m)
  }
]