<template>
  <div>
    <NuxtPwaAssets />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <!-- use content in pages directory -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
