import * as Sentry from '@sentry/vue'

const lazyLoadSentryIntegrations = async () => {
  // don't load on server
  if (!process.client) {
    return
  }

  const { Replay } = await import('@sentry/vue')
  Sentry.addIntegration(
    new Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  )
}

const getSentryIntegrations = () => {
  // don't load on server
  if (!process.client) {
    return []
  }

  const router = useRouter()
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  })

  return [browserTracing]
}

export default defineNuxtPlugin({
  name: 'sentry-client',
  parallel: true,
  setup(nuxtApp) {
    const config = useRuntimeConfig()

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC,
      environment: config.public.SENTRY_ENVIRONMENT,
      integrations: getSentryIntegrations(),
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE, // Change in prod
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^\/api\//,
        config.public.SENTRY_PROPAGATION_TARGET,
      ],
      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE,
    })

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations()
  },
})
